var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import classNames from 'classnames';
import validator from 'validator';
import styles from './Auth.css';
import { login, cypressLogin } from '../../../api/Auth';
import LinkWithArrow, { LinkWithArrowVariant } from '../../tailwind_common/LinkWithArrow';
import Button from '../../tailwind_common/Button';
import TextField from '../../tailwind_common/TextField';
/* eslint-disable react/jsx-no-literals */
/*
  disabling jsx-no-literals as this is internal only (no need to translate)
*/
var INVALID_EMAIL_ERROR = 'Need a valid email address';
var Auth = /** @class */ (function (_super) {
    __extends(Auth, _super);
    function Auth(props) {
        var _this = _super.call(this, props) || this;
        _this.submitFormOnEnter = function (event) {
            var email = _this.state.email;
            var key = event.which || event.keyCode;
            if (key === 13) {
                // TODO(annie): moar validation here
                if (!email) {
                    _this.setState({ emailInputError: INVALID_EMAIL_ERROR });
                    return;
                }
                _this.handleSubmit();
            }
        };
        _this.setEmailSubmittedErrorAndReset = function () {
            _this.setState({ emailSubmitted: false, emailSubmittedError: true }, function () {
                setTimeout(function () {
                    _this.setState({ emailSubmittedError: false });
                }, 3000);
            });
        };
        _this.handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var email, href, reqBody, response;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        email = this.state.email;
                        // TODO(annie): moar validation here
                        if (!email) {
                            this.setState({ emailInputError: INVALID_EMAIL_ERROR });
                            return [2 /*return*/];
                        }
                        if (!validator.isUUID(email)) return [3 /*break*/, 2];
                        href = window.location.href;
                        reqBody = {
                            uuid: email,
                            url: href,
                        };
                        return [4 /*yield*/, cypressLogin(reqBody)];
                    case 1:
                        response = _b.sent();
                        if (!response || response.error || ((_a = response.data) === null || _a === void 0 ? void 0 : _a.error)) {
                            this.setEmailSubmittedErrorAndReset();
                            return [2 /*return*/];
                        }
                        if (response && response.data && !!response.data.ok) {
                            return [2 /*return*/, response.data.text().then(function (t) {
                                    throw Error(t);
                                })];
                        }
                        this.setState({ cypressUuidSubmittedSuccess: true });
                        return [2 /*return*/];
                    case 2:
                        if (!validator.isEmail(email)) {
                            this.setState({ emailInputError: INVALID_EMAIL_ERROR });
                            return [2 /*return*/];
                        }
                        this.setState({ emailSubmitted: true }, function () { return __awaiter(_this, void 0, void 0, function () {
                            var href, reqBody, response;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        href = window.location.href;
                                        reqBody = {
                                            email: validator.normalizeEmail(email),
                                            url: href,
                                        };
                                        return [4 /*yield*/, login(reqBody)];
                                    case 1:
                                        response = _b.sent();
                                        if (!response || response.error || ((_a = response.data) === null || _a === void 0 ? void 0 : _a.error)) {
                                            this.setEmailSubmittedErrorAndReset();
                                        }
                                        else if (response && response.data && !!response.data.ok) {
                                            return [2 /*return*/, response.data.text().then(function (t) {
                                                    throw Error(t);
                                                })];
                                        }
                                        else {
                                            this.setState({ emailSubmittedSuccess: true, emailSubmitted: false });
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleChangeEmail = function (email) { return _this.setState({ email: email, emailInputError: '' }); };
        _this.getButtonText = function (resend) {
            if (resend === void 0) { resend = false; }
            var _a = _this.state, emailSubmitted = _a.emailSubmitted, emailSubmittedError = _a.emailSubmittedError;
            var buttonText = resend ? 'Resend login email' : 'Continue';
            if (emailSubmitted) {
                buttonText = 'Sending email...';
            }
            if (emailSubmittedError) {
                buttonText = 'Error encountered, please try again';
            }
            return buttonText;
        };
        _this.renderSubmitButton = function (resend) {
            var _a;
            if (resend === void 0) { resend = false; }
            var _b = _this.state, emailSubmitted = _b.emailSubmitted, emailSubmittedError = _b.emailSubmittedError;
            return (React.createElement("div", { className: styles.auth__submitButtonContainer },
                React.createElement(Button, { className: classNames((_a = {},
                        _a[styles['auth__submitButton--submitted']] = emailSubmitted,
                        _a[styles['auth__submitButton--error']] = emailSubmittedError,
                        _a)), onClick: _this.handleSubmit }, _this.getButtonText(resend))));
        };
        _this.state = {
            email: '',
            emailInputError: '',
            emailSubmitted: false,
            emailSubmittedError: false,
            emailSubmittedSuccess: false,
            cypressUuidSubmittedSuccess: false,
        };
        return _this;
    }
    Auth.prototype.componentDidMount = function () {
        document.addEventListener('keydown', this.submitFormOnEnter);
    };
    Auth.prototype.componentWillUnmount = function () {
        document.removeEventListener('keydown', this.submitFormOnEnter);
    };
    Auth.prototype.render = function () {
        var _a = this.state, emailInputError = _a.emailInputError, emailSubmittedSuccess = _a.emailSubmittedSuccess, cypressUuidSubmittedSuccess = _a.cypressUuidSubmittedSuccess;
        if (cypressUuidSubmittedSuccess) {
            return (React.createElement("div", { className: styles.auth },
                React.createElement(LinkWithArrow, { text: "Go to editor", href: window.location.href, variant: LinkWithArrowVariant.Left })));
        }
        if (emailSubmittedSuccess) {
            return (React.createElement("div", { className: styles.auth },
                React.createElement("div", { className: styles.auth__title }, "Login email sent"),
                React.createElement("div", { className: styles.auth__helperText },
                    React.createElement("div", null,
                        "Check your inbox for a login link from ",
                        React.createElement("span", null, "Clearstep"),
                        ". If you do not see a login email, please check your spam folder."),
                    React.createElement("div", null,
                        "If you're a new user, welcome! Check your email inbox for a login link to start using ",
                        React.createElement("span", null, "Clearstep"),
                        ".")),
                React.createElement("div", { className: styles.auth__resendHelperText }, "Didn't receive a login email?"),
                this.renderSubmitButton(true),
                React.createElement(LinkWithArrow, { text: "Back to home", href: "https://clearstep.health", variant: LinkWithArrowVariant.Left })));
        }
        return (React.createElement("div", { className: styles.auth },
            React.createElement("div", { className: styles.auth__title }, "Log in"),
            React.createElement("div", { className: styles.auth__input },
                React.createElement(TextField, { onChange: this.handleChangeEmail, ariaLabel: "Input email address", placeholder: "Email address", error: emailInputError })),
            React.createElement("div", { className: styles.auth__helperText },
                React.createElement("div", null, "Enter your email address to start logging in."),
                React.createElement("div", null,
                    "If you're a new user, welcome! Enter your email address to create a",
                    ' ',
                    React.createElement("span", null, "Clearstep"),
                    " account.")),
            this.renderSubmitButton(),
            React.createElement(LinkWithArrow, { text: "Back to home", href: "/", variant: LinkWithArrowVariant.Left })));
    };
    return Auth;
}(React.Component));
export default Auth;

import * as React from 'react';
import { Suspense } from 'react';
import Auth from './Auth';
import AppHeader from '../AppHeader/AppHeader';
import styles from './AuthPage.css';
var AuthPage = function () { return (React.createElement(Suspense, { fallback: "" },
    React.createElement("div", { className: styles.authPage },
        React.createElement(AppHeader, { applyBottomBorder: true }),
        React.createElement("div", { className: styles.authPage__content },
            React.createElement(Auth, null))))); };
export default AuthPage;
